import { useMsal } from '@azure/msal-react';
import appsettings from '../../appsettings';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { IEntry, setEntries, setLoading, setUnlockingId } from './entriesSlice';
import { useEffect } from 'react';
import axios from 'axios';

interface IAxiosConfig {
    baseURL: string;
}
  
const axiosConfig: IAxiosConfig = {
    baseURL: `${appsettings.api.host}/api`,
};

export interface IUseEntries {
    loading: boolean;
    entries: IEntry[];
    refreshEntries(): void;
    unlockEntry(id: number, 
        onSuccess?:() => void, 
        onError?: (error: unknown) => void
    ): void;
    unlockingId: number | null;
}

export const useEntries = (): IUseEntries => {
    const { instance } = useMsal();
    const activeAccount = useAppSelector((state) => state.auth.activeAccount);
    const entries = useAppSelector((state) => state.entries.entries);
    const loading = useAppSelector((state) => state.entries.loading);
    const unlockingId = useAppSelector((state) => state.entries.unlockingId);
    const dispatch = useAppDispatch();
    
    /**
     * Load entries on first render.
     * This will load entries for the current active user
     * and will trigger redirect if tokens are expired.
     */
    useEffect(()=>{
        refreshEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    /**
     * Load entries on account changes.
     * This will load entries for the current active user
     * and will trigger redirect if tokens are expired.
     */
    useEffect(()=>{
        refreshEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeAccount])

    /**
     * @description This will try to fetch an accesstoken and
     * fallback to a redirect on failure.
     */
    const getAccesstoken = async (scopes: string[]): Promise<string> => {
        try {
            const result = await instance.acquireTokenSilent({
            scopes,
            });
            return result.accessToken;

        } catch (error) {
            
            console.log(
            'getAccesstoken - error while fetching token, user will be redirected.',
            { error, scopes },
            );

            await instance.acquireTokenRedirect({
            scopes,
            });

            return ''; // should not return because client will redirected
        }
    };

    const refreshEntries = async () => {
        try {
            dispatch(setLoading(true));
            const token = await getAccesstoken(appsettings.auth.apiScopes);
            const response = await axios.get<IEntry[]>(
                `/entries`,
                {
                    ...axiosConfig,
                    headers: {
                    Authorization: `Bearer ${token}`,
                    },
                },
            );

            dispatch(setEntries(response.data))
        }
        catch(error){
            console.log("Error while refreshing data", error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const unlockEntry = async (id: number, 
        onSuccess:(()=>void)|undefined = undefined, 
        onError:((error:unknown)=>void)|undefined = undefined) => {
        try {
            dispatch(setUnlockingId(id));
            const token = await getAccesstoken(appsettings.auth.apiScopes);
            await axios.post<IEntry[]>(
                `/entries/${id}/unlock`,
                null,
                {
                    ...axiosConfig,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            if (onSuccess !== undefined) onSuccess();
        }
        catch(error){
            console.log("Error while unlocking entry", error);
            if (onError !== undefined) onError(error);
        }
        finally {
            dispatch(setUnlockingId(null));
        }
    }

    return {
        loading,
        entries,
        refreshEntries,
        unlockEntry,
        unlockingId
    };
};
