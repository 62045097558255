export type AppSettings = {
  auth: {
    clientId: string;
    authority: string;
    redirectUrl: string;
    postLogoutUrl: string;
    loginScopes: string[];
    apiScopes: string[];
  };
  api: {
    host: string;
  };
};

export const appsettings: AppSettings = {
  auth: {
    clientId: window.AppSettings.auth.clientId,
    authority: window.AppSettings.auth.authority,
    redirectUrl: window.AppSettings.auth.redirectUrl,
    postLogoutUrl: window.AppSettings.auth.logoutRedirectUrl,
    loginScopes: window.AppSettings.auth.loginScopes?.split(','),
    apiScopes: window.AppSettings.auth.apiScopes?.split(','),
  },
  api: {
    host: window.AppSettings.api.baseUrl,
  }
};

export default appsettings;
