/// <reference types="vite-plugin-svgr/client" />

import React from 'react';
import { Button, Stack, SvgIcon, Typography } from '@mui/material';
import WaveSvg from '../assets/illustrations/wave.svg?react';
import { PageLayout } from '../components/PageLayout/PageLayout';
import { useMsal } from '@azure/msal-react';
import appsettings from '../appsettings';

/**
 * @description This is just a landing
 * page after logout to prevent user
 * being redirected again to login.
 */
export const LogoutPage: React.FC = () => {
  const {instance} = useMsal();

  return (
    <PageLayout title='Backe: Logget ut'>
      <Stack spacing={2} sx={{ alignItems: 'center', px: { xs: 1, sm: 0 } }}>
        <SvgIcon
          sx={{
            width: { xs: '100%', sm: '292px' },
            height: { xs: '100%', sm: '351px' },
          }}
          component={WaveSvg}
          viewBox='0 0 292 351'
        />
        <Typography variant='body1' align='center' sx={{ fontWeight: 700 }}>
          Du er nå logget ut!
        </Typography>
        <Button
          variant='contained'
          onClick={() => {
            instance.acquireTokenRedirect({
              authority: appsettings.auth.authority,
              scopes: appsettings.auth.loginScopes,
              redirectUri: appsettings.auth.redirectUrl,
              prompt: 'select_account',
              redirectStartPage: '/'
            })
          }}
        >
          LOGG INN PÅ NYTT
        </Button>
      </Stack>
    </PageLayout>
  )
};

export default LogoutPage;
