import { useMsal } from '@azure/msal-react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import * as actions from './accountSlice';
import { AccountInfo } from '@azure/msal-browser';
import axios, { ResponseType } from 'axios';

interface IAxiosConfig {
  baseURL: string;
  responseType: ResponseType;
}

const axiosConfig: IAxiosConfig = {
  baseURL: `https://graph.microsoft.com`,
  responseType: 'json',
};

interface IUseAccount {
  activeAccount: AccountInfo | null;
  accounts: AccountInfo[];
  profilePicture: string;
  shortName: string;
  setActiveAccount(account:AccountInfo | null):void;
}

export const useAccount = ():IUseAccount => {
  const { instance } = useMsal();
  const activeAccount = useAppSelector((state) => state.auth.activeAccount);
  const accounts = useAppSelector((state) => state.auth.accounts);
  const profilePicture = useAppSelector((state) => state.auth.profilePicture);
  const shortName = useAppSelector((state) => state.auth.shortName);
  const dispatch = useAppDispatch();
  
  const getAccesstoken = async (scopes: string[]): Promise<string | null> => {
    try {
      const result = await instance.acquireTokenSilent({
        scopes,
      });
      return result.accessToken;

    } catch (error) {
      console.log(
        'useProfilePicture - error while fetching token.',
        { error, scopes },
      );
      throw new Error('Interaction is required')
    }
  };

  /**
   * @description This will try to fetch
   * the user's profilepicture. Any errors will
   * be swallowed and the user will not be redirected
   * in case of authentication error.
   * @returns 
   */
  const tryGetProfilePictureUrl = async (): Promise<string> => {
    try {
      const token = await getAccesstoken([
        'https://graph.microsoft.com/.default',
      ]);
  
      const response = await axios.get<Blob>(`/v1.0/me/photos/48x48/$value`, {
        ...axiosConfig,
        responseType: 'blob',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'image/jpg',
        },
      });
  
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(response.data);
      return imageUrl;
    }
    catch (error) {
      console.log(
        'Unable to fetch profile picture for user. This would happen if the user is a guest user or if interaction is required by the user',
      );

      return '';
    }
  };

  const getAvatarNick = (name: string | undefined): string => {
    if (name !== null && name !== undefined && name.length > 1) {
      return name[0];
    }
    else {
      return ''
    }
  }

  const setActiveAccount = (account:AccountInfo | null) => {
    instance.setActiveAccount(account);
    dispatch(actions.setActiveAccount(account));
    dispatch(actions.setAccounts(instance.getAllAccounts()))
    dispatch(actions.setProfilePicture(''));
    dispatch(actions.setShortName(getAvatarNick(account?.name)))
    tryGetProfilePictureUrl()
      .then(url => dispatch(actions.setProfilePicture(url)))
  }

  return {
    activeAccount,
    accounts,
    profilePicture,
    shortName,
    setActiveAccount
  }
}
