import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './features/auth/msalConfig.ts'

PublicClientApplication.createPublicClientApplication(msalConfig)
  .then(pca => {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <App pca={pca} />
      </React.StrictMode>,
    )
  })
  .catch((error)=>{
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <>
          {error}
        </>
      </React.StrictMode>,
    )
  });

