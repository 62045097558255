import { configureStore } from '@reduxjs/toolkit'
import accountReducer from '../features/auth/accountSlice';
import entriesReducer from '../features/entries/entriesSlice';
import guestEntriesReducer from '../features/guestEntries/guestEntriesSlice';

export const store = configureStore({
  reducer: {
    auth: accountReducer,
    entries: entriesReducer,
    guestEntries: guestEntriesReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
