/// <reference types="vite-plugin-svgr/client" />

import { Box, SvgIcon, Typography } from '@mui/material';
import LockSvg from '../../assets/illustrations/lock.svg?react';

export interface IErrorProps {
    title?: string;
    message: string;
}

export const Error = ({message, title}:IErrorProps): JSX.Element => {
    return (
        <Box sx={{ 
            display: 'flex', 
            flexDirection: {xs: 'column', md: 'row'},
            p: 3,
            backgroundColor: '#E9EFF2',
            maxWidth: '600px' }}>
            <SvgIcon
                sx={{
                    width: '100%',
                    height: '100%',
                    flexGrow: 1,
                    maxWidth: '200px',
                    maxHeight: '200px',
                    alignSelf: {xs: 'center', md: 'flex-start'},
                    pr: {xs: 0, md: 3}
                }}
                component={LockSvg}
                viewBox='0 0 200 200'
            />
            <Box sx={{display: 'flex', alignItems: {xs: 'flex-start', md: 'center'}}}>
                <Box>
                    <Typography variant='body1' fontWeight='bold'>
                        {title}
                    </Typography>
                    <Typography variant='body1'>
                        {message}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
