import { IPublicClientApplication } from '@azure/msal-browser';
import { CustomNavigationClient } from './CustomNavigationClient';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 *@description This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 * It's important that this component is rendered before the Msal context provider!
 */
export const ClientSideNavigation = ({
  pca,
  children,
}: {
  pca: IPublicClientApplication;
  children: JSX.Element;
}) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
};
