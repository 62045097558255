/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAccount } from './useAccount';

type AccountStateInitializer = {
  children?: React.ReactNode;
};

export const AccountStateInitializer = ({
  children
}: AccountStateInitializer): JSX.Element => {
  const { instance } = useMsal();
  const { setActiveAccount } = useAccount();

  useEffect(()=>{
    if (instance.getAllAccounts().length > 0){
      setActiveAccount(instance.getActiveAccount() ?? instance.getAllAccounts()[0]);
    }
  },[])

  return <>{children}</>
}

export default AccountStateInitializer;
