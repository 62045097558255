import { SimplePageLayout } from '../components/SimplePageLayout/SimplePageLayout';
import ReactLoading from 'react-loading';
import { Box } from '@mui/material';

export const LoadingPage = () => {
  return (
    <SimplePageLayout>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <ReactLoading type='spin' height={150} width={150} />
      </Box>
    </SimplePageLayout>
  );
};

export default LoadingPage;
