import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

export interface IDoorDialogProps {
    openDialog: boolean;
    entryName: string;
    handleClose():void;
    handleOpenDoor():void;
}

export const DoorDialog = ({openDialog, entryName, handleClose, handleOpenDoor}:IDoorDialogProps): JSX.Element => {
    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{color:'black'}}>Ønsker du å åpne døren «{entryName}»?</DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent: 'space-between', p: 3}}>
                <Button sx={{borderRadius: '4px', backgroundColor: '#8F9AAA', color: '#000000'}} variant="contained" onClick={handleClose}>Avbryt</Button>
                <Button sx={{borderRadius: '4px'}} variant="contained" onClick={handleOpenDoor} autoFocus>Åpne døren</Button>
            </DialogActions>
        </Dialog>
    )
}
