import appsettings from '../../appsettings';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { IEntry, setEntries, setLoading, setUnlockingId } from './guestEntriesSlice';
import { useEffect } from 'react';
import axios, { AxiosBasicCredentials } from 'axios';
import { useSearchParams } from 'react-router-dom';

interface IAxiosConfig {
    baseURL: string;
}
  
const axiosConfig: IAxiosConfig = {
    baseURL: `${appsettings.api.host}/api`,
};

export interface IUseEntries {
    loading: boolean;
    entries: IEntry[];
    refreshEntries(): void;
    unlockEntry(id: number, 
        onSuccess?:() => void, 
        onError?: (error: unknown) => void
    ): void;
    unlockingId: number | null;
}

export const useGuestEntries = (): IUseEntries => {
    const [searchParams] = useSearchParams();
    const entries = useAppSelector((state) => state.guestEntries.entries);
    const loading = useAppSelector((state) => state.guestEntries.loading);
    const unlockingId = useAppSelector((state) => state.guestEntries.unlockingId);
    const dispatch = useAppDispatch();
    
    const getAuth = ():AxiosBasicCredentials | undefined => {
        const id = searchParams.get("id");
        const key = searchParams.get("key");

        if (id && key) return {
            username: id,
            password: key
        }
    }

    /**
     * Load entries on first render.
     * This will load entries for the current active user
     * and will trigger redirect if tokens are expired.
     */
    useEffect(()=>{
        refreshEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const refreshEntries = async () => {
        try {
            dispatch(setLoading(true));
            const response = await axios.get<IEntry[]>(
                `/guest-entries`,
                {
                    ...axiosConfig,
                    auth: getAuth()
                },
            );

            dispatch(setEntries(response.data))
        }
        catch(error){
            console.log("Error while refreshing data", error);
        }
        finally{
            dispatch(setLoading(false));
        }
    }

    const unlockEntry = async (id: number, 
        onSuccess:(()=>void)|undefined = undefined, 
        onError:((error:unknown)=>void)|undefined = undefined) => {
        try {
            dispatch(setUnlockingId(id));
            await axios.post<IEntry[]>(
                `/guest-entries/${id}/unlock`,
                null,
                {
                    ...axiosConfig,
                    auth: getAuth()
                },
            );
            if (onSuccess !== undefined) onSuccess();
        }
        catch(error){
            console.log("Error while unlocking entry", error);
            if (onError !== undefined) onError(error);
        }
        finally {
            dispatch(setUnlockingId(null));
        }
    }

    return {
        loading,
        entries,
        refreshEntries,
        unlockEntry,
        unlockingId
    };
};
