import { AccountInfo} from '@azure/msal-browser';
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IAuthState {
    shortName: string;
    activeAccount: AccountInfo | null;
    profilePicture: string;
    accounts: AccountInfo[];
}

const initialState: IAuthState = {
    shortName: '',
    activeAccount: null,
    profilePicture: '',
    accounts: [],
};

export const accountSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setActiveAccount: (state, action: PayloadAction<AccountInfo | null>) => {
        state.activeAccount = action.payload
    },
    setAccounts: (state, action: PayloadAction<AccountInfo[]>) => {
        state.accounts = action.payload
    },
    setProfilePicture: (state, action: PayloadAction<string>) => {
      state.profilePicture = action.payload
    },
    setShortName: (state, action: PayloadAction<string>) => {
      state.shortName = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setActiveAccount, setAccounts, setProfilePicture, setShortName } = accountSlice.actions

export default accountSlice.reducer