import { Box, Button, Typography } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export interface IDoorItemProps {
    name: string;
    onClick():void;
}

export const DoorItem = ({name, onClick}:IDoorItemProps): JSX.Element => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: {xs: 'column', sm: 'row'},
            justifyContent: 'center', 
            alignItems: 'center', 
            p: 3, 
            width: '100%',
            maxWidth: '600px',
            backgroundColor: '#E9EFF2',
            border: '1px solid #F0F0F0',
            borderRadius: '12px'}}>
            <Box>
                <LockOpenIcon sx={{width: '40px', height: '40px'}}/>
            </Box>
            <Box sx={{flexGrow: 1, px: {xs: 0, sm: 2}, py: {xs: 2, sm: 0}}}>
                <Typography variant='body1' fontWeight='bold'>
                    {name}
                </Typography>
            </Box>
            <Box>
                <Button sx={{borderRadius: '4px'}} variant="contained" onClick={onClick}>Åpne døren</Button>
            </Box>
        </Box>
    )
}
