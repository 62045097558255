import { OAuth2CallbackPage } from './OAuth2CallbackPage';
import { EmployeePage } from './EmployeePage';
import { LogoutPage } from './LogoutPage';
import { NotFoundPage } from './NotFoundPage';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { LoadingPage } from './LoadingPage';
import { AuthErrorPage } from './AuthErrorPage';
import appsettings from '../appsettings';
import { GuestPage } from './GuestPage';

export const Pages = (): JSX.Element => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route
        path='/'
        element={
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{
              authority: appsettings.auth.authority,
              redirectUri: appsettings.auth.redirectUrl,
              scopes: appsettings.auth.loginScopes
            }}
            loadingComponent={LoadingPage}
            errorComponent={AuthErrorPage}
          >
            <EmployeePage />
          </MsalAuthenticationTemplate>
        }
      />
      <Route path='/guests' element={<GuestPage />} />
      <Route path='/logout' element={<LogoutPage />} />
      <Route path='/oauth2-callback' element={<OAuth2CallbackPage />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};
