import { Configuration } from '@azure/msal-browser';
import { appsettings } from '../../appsettings';

export const msalConfig: Configuration = {
  auth: {
    clientId: appsettings.auth.clientId,
    authority: appsettings.auth.authority,
    redirectUri: appsettings.auth.redirectUrl,
    postLogoutRedirectUri: appsettings.auth.postLogoutUrl,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};
