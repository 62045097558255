/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useMsal } from '@azure/msal-react';
import { Divider, useTheme } from '@mui/material';
import { useState } from 'react';
import appsettings from '../../appsettings';
import { useAccount } from '../../features/auth/useAccount';
import { Add } from '@mui/icons-material';
import { backe } from "../../styles/jsColors";

export const ProfileMenu = () => {
  const { instance } = useMsal();
  const theme = useTheme();
  const { activeAccount, accounts, profilePicture, shortName, setActiveAccount } = useAccount();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={activeAccount?.username ?? ''}>
          <Typography
            variant='h6'
            noWrap
            sx={{
              display: { xs: 'none', sm: 'block' },
              fontWeight: 400,
            }}
          >
            {activeAccount?.name ?? ''}
          </Typography>
        </Tooltip>
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
            }}
            src={profilePicture}
          >
            {shortName}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        disableScrollLock={true}
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        {accounts.map((account) => 
        <Box key={account.homeAccountId}>
          <MenuItem onClick={() => setActiveAccount(account)}>
            {account.homeAccountId === activeAccount?.homeAccountId ?
              <Avatar sx={{ bgcolor: backe.yellow, color: backe.blue }}/>
            :
              <Avatar/> 
            }
            <Typography noWrap>
              {account?.username ?? ''}
            </Typography>
          </MenuItem>
        </Box>
        )}

        <Divider />
        <MenuItem onClick={() => instance.acquireTokenRedirect({
          authority: appsettings.auth.authority,
          redirectUri: appsettings.auth.redirectUrl,
          scopes: appsettings.auth.loginScopes,
          prompt: 'select_account'
        })}>
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <Typography noWrap>
            Legg til bruker
          </Typography>
        </MenuItem>
        <MenuItem onClick={()=>{
            instance.logout({
              account: activeAccount,
              postLogoutRedirectUri: accounts.length > 1 ? '/' : '/logout',
              authority: appsettings.auth.authority
            })
          }}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <Typography noWrap>
              Logg ut
            </Typography>
          </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default ProfileMenu;
