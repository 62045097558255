import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import BackeLogo from '../../assets/logos/backe.png';

export const SimplePageHeader = (): JSX.Element => {
  return (
    <AppBar position='static' elevation={0} sx={{p: {xs: 1, lg: 2}}}>
      <Toolbar disableGutters>
        <Box
          sx={{
            flexGrow: 0,
            display: { xs: 'flex' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={BackeLogo}
              alt='Backe logo'
              style={{
                maxHeight: '45px',
                width: 'auto',
                height: 'auto',
              }}
            />
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 0, minWidth: 0 }}></Box>
      </Toolbar>
    </AppBar>
  );
};

export default SimplePageHeader;
