/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Stack,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import PageHeader from './PageHeader';
import { Banner } from '../Banner/Banner';

type PageLayoutProps = {
  title?: string;
  children?: React.ReactNode;
};

export const PageLayout = ({
  title = 'Backe',
  children
}: PageLayoutProps): JSX.Element => {
  const theme = useTheme();

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.primary.main,
        minHeight: '100%',
        minWidth: '100%',
      }}
    >
      <PageHeader />
      <Banner />
      <Container
          sx={{
            flexGrow: 1,
          }}>
          {children}
      </Container>
    </Stack>
  );
};

export default PageLayout;
