/// <reference types="vite-plugin-svgr/client" />

import React from 'react';
import { Stack, SvgIcon, Typography } from '@mui/material';
import NotFoundSvg from '../assets/illustrations/not-found.svg?react';
import { SimplePageLayout } from '../components/SimplePageLayout/SimplePageLayout';

export const NotFoundPage: React.FC = () => {
  return (
    <SimplePageLayout title='Backe: Side ikke funnet'>
      <Stack spacing={2} sx={{ alignItems: 'center', px: { xs: 1, sm: 0 } }}>
        <SvgIcon
          sx={{
            width: { xs: '100%', sm: '252px' },
            height: { xs: '100%', sm: '314px' },
          }}
          component={NotFoundSvg}
          viewBox='0 0 231 171'
        />
        <Typography variant='body1' align='center' sx={{ fontWeight: 700 }}>
          Denne siden fant vi desverre ikke!
        </Typography>
      </Stack>
    </SimplePageLayout>
  );
};

export default NotFoundPage;
