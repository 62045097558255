import { Box, Typography } from '@mui/material';
export interface IBannerProps {
    title?: string;
}

export const Banner = ({title = 'Tilgangsportalen'}:IBannerProps): JSX.Element => {
    return (
        <Box sx={{pt:'48px', pb:'32px'}}>
            <Typography variant='h3' align='center' color='#FFFFFF'>
                {title}
            </Typography>
        </Box>
    )
}
