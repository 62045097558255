/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { SimplePageLayout } from '../components/SimplePageLayout/SimplePageLayout';
import ReactLoading from 'react-loading';
import { Box } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { useAccount } from '../features/auth/useAccount';

export const OAuth2CallbackPage: React.FC = () => {
  const { instance } = useMsal();
  const { setActiveAccount } = useAccount();

  useEffect(()=>{
    instance
    .handleRedirectPromise()
    .then((data) => {
      if (data?.account) {
        console.log(
          'A redirect was processed and result contains an account. Setting result account to active account',
        );
        setActiveAccount(data.account);
      }
    })
    .catch();
  },[])

  return (
    <SimplePageLayout title='Backe: Logger inn'>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <ReactLoading type='spin' height={150} width={150} />
      </Box>
    </SimplePageLayout>
  );
};

export default OAuth2CallbackPage;
