import { Box, Stack } from '@mui/material';
import { SimplePageLayout } from '../components/SimplePageLayout/SimplePageLayout';
import { useGuestEntries } from '../features/guestEntries/useGuestEntries';
import LoadingPage from './LoadingPage';
import { toast } from 'react-toastify';
import { Error } from '../components/Error/Error';
import { useState } from 'react';
import { DoorDialog } from '../components/DoorDialog/DoorDialog';
import { DoorItem } from '../components/DoorItem/DoorItem';

interface IDoorDialogState {
  id: number,
  name: string,
}

export const GuestPage = (): JSX.Element => {
  const { entries, loading, unlockEntry } = useGuestEntries();
  const [openDialog, setOpenDialog] = useState<IDoorDialogState | undefined>(undefined);

  const handleOpenDialog = (entryId: number, entryName: string) => {
    setOpenDialog({
      id: entryId,
      name: entryName
    });
  }

  const handleCloseDialog = ():void => {
    setOpenDialog(undefined);
  }

  const handleOpenDoor = ():void => {
    if (openDialog === undefined) return;
    unlockEntry(openDialog.id, 
      ()=> {
        toast.success("Dør åpnet 🚪🚶", {pauseOnFocusLoss: false, pauseOnHover: false, autoClose: 1000, hideProgressBar: true})
      }, 
      ()=>{
        toast.error("Vi klarte ikke å åpne døren")
      });
    setOpenDialog(undefined);
  }

  if (loading) return <LoadingPage />
  if (entries.length <= 0) return (
    <SimplePageLayout title='Backe'>
      <Box sx={{display:'flex', justifyContent: 'center'}}>
        <Error title='Du har for øyeblikket ingen aktive tilganger' message='Hvis du skal delta på et møte hos oss kan du be møtearrangøren om å opprette en tilgang til deg.' />
      </Box>
    </SimplePageLayout>
  );

  return (
    <SimplePageLayout title='Backe'>
      <DoorDialog openDialog={openDialog !== undefined} entryName={openDialog?.name ?? ''} handleClose={handleCloseDialog} handleOpenDoor={handleOpenDoor} />
      <Stack sx={{alignItems: 'center'}} spacing={2}>
        {entries.map(entry => <DoorItem name={entry.name} onClick={() => handleOpenDialog(entry.id, entry.name)} />)}
      </Stack>
    </SimplePageLayout>
  );
};
