import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import BackeLogo from '../../assets/logos/backe.png';
import ProfileMenu from './ProfileMenu';
import { useAppSelector } from '../../redux/hooks';

export interface IPageHeaderProps {
  enableLink?: boolean;
}

const getLogoWithHomeLink = ():JSX.Element => {
  return (
    <Link
      to={'/'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img
        src={BackeLogo}
        alt='Backe logo'
        style={{
          maxHeight: '45px',
          width: 'auto',
          height: 'auto',
        }}
      />
    </Link>
  )
}

const getLogo = ():JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img
        src={BackeLogo}
        alt='Backe logo'
        style={{
          maxHeight: '45px',
          width: 'auto',
          height: 'auto',
        }}
      />
    </Box>
  )
}

export const PageHeader = ({enableLink = true}:IPageHeaderProps): JSX.Element => {
  const activeAccount = useAppSelector((state) => state.auth.activeAccount);

  return (
    <AppBar position='static' elevation={0} sx={{p: {xs: 1, lg: 2}}}>
      <Toolbar disableGutters>
        <Box
          sx={{
            flexGrow: 0,
            display: { xs: 'flex' },
          }}
        >
          {enableLink ? getLogoWithHomeLink() : getLogo()}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 0, minWidth: 0 }}>
          {activeAccount &&
            <ProfileMenu />
          }
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default PageHeader;
