import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import mainTheme from './styles/mainTheme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Pages } from './pages/Pages';
import { MsalProvider } from '@azure/msal-react';
import { ClientSideNavigation } from './features/auth/ClientSideNavigation';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { AccountStateInitializer } from './features/auth/AccountStateInitializer';

export interface IAppProps {
  pca: IPublicClientApplication
}

export const App = ({pca}: IAppProps): JSX.Element => {
  return (
      <BrowserRouter>
        <ClientSideNavigation pca={pca}>
          <MsalProvider instance={pca}>
            <Provider store={store}>
              <AccountStateInitializer>
                <CssBaseline />
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={mainTheme}>
                    <ToastContainer />
                    <Pages />
                  </ThemeProvider>
                </StyledEngineProvider>
              </AccountStateInitializer>
            </Provider>
          </MsalProvider>
        </ClientSideNavigation>
      </BrowserRouter>
  );
};

export default App;