import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface IEntry {
  id: number;
  name: string;
}

interface IEntriesState {
    entries: IEntry[];
    loading: boolean; // set when fetching entries from API
    unlockingId: number | null; // set when unlocking an entry
}

const initialState: IEntriesState = {
    entries: [],
    loading: true,
    unlockingId: null,
};

export const entriesSlice = createSlice({
  name: 'entries',
  initialState,
  reducers: {
    setEntries: (state, action: PayloadAction<IEntry[]>) => {
        state.entries = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setUnlockingId: (state, action: PayloadAction<number | null>) => {
      state.unlockingId = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setEntries, setLoading, setUnlockingId } = entriesSlice.actions

export default entriesSlice.reducer